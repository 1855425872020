<template>
  <v-col cols="12" class="conditions">
    <v-row justify="end">
      <a
        :href="`https://ayuda.dvacapital.com/es/articles/5813988-terminos-y-condiciones-gift-card`"
        target="_blank"
        class="conditions-link"
      >
        <span class="terms-conditions"> Ver Condiciones </span>
      </a>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "Conditions",
};
</script>

<style lang="scss" scoped>
.conditions {
  margin: 60px 0;
  @media (max-width: 600px) {
    margin: 20px 0;
  }
}
.terms-conditions {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 160% !important;
  color: #333333;
}

.conditions-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>
