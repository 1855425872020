import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import { AuthenticationGuard } from "vue-auth0-plugin";

// GIFT
const ChooseGiftCard = () => import("../views/gift/ChooseGiftCard.vue");
const PurchaseForm = () => import("../views/gift/PurchaseForm.vue");
const Payment = () => import("../views/gift/Payment.vue");
const GiftSuccess = () => import("../views/gift/GiftSuccess.vue");

//REDEEM
const GiftCardCodeForm = () => import("../views/redeem/GiftCardCodeForm.vue");
const CustomerAccount = () => import("../views/redeem/CustomerAccount.vue");
const RedeemGiftCard = () => import("../views/redeem/RedeemGiftCard.vue");
const RedeemSuccess = () => import("../views/redeem/RedeemSuccess.vue");

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: "/choose-gift-card",
    name: "ChooseGiftCard",
    component: ChooseGiftCard,
  },
  {
    path: "/purchase",
    name: "Purchase",
    component: PurchaseForm,
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/gift-success",
    name: "GiftSuccess",
    component: GiftSuccess,
  },
  {
    path: "/redeem",
    name: "Redeem",
    component: GiftCardCodeForm,
  },
  {
    path: "/customer-account",
    name: "customerAccount",
    component: CustomerAccount,
  },
  {
    path: "/redeem-gift-card",
    name: "redeemGiftCard",
    component: RedeemGiftCard,
    beforeEnter: AuthenticationGuard,
  },
  {
    path: "/redeem-success",
    name: "redeemSuccess",
    component: RedeemSuccess,
    beforeEnter: AuthenticationGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 };
  },
  routes,
});

export default router;
