// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  display: {
    mobileBreakpoint: "md", // This is equivalent to a value of "960"
  },
  defaults: {
    global: {
      mobileBreakpoint: "960",
      breakpoint: {
        mobileBreakpoint: "960", // This is equivalent to a value of 960
      },
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
