<template>
  <v-app class="app-width">
    <v-main id="main">
      <Header />
      <router-view v-bind:style="routerStyle" />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import { getCustomerQuery } from "./gql/queries/customers";
import { mapMutations } from "vuex";
import { mapState } from "vuex";

export default {
  name: "App",

  components: {
    Header,
    Footer,
  },

  inject: ["auth"],

  data: () => ({
    // GQL Queries/Mutations
    getCustomerQuery,
  }),

  computed: {
    ...mapState(["customerData", "loadingCustomerData"]),
    routerStyle: function () {
      return {
        padding: this.$vuetify.display.mobile ? "60px 10px" : "160px 20px",
        "padding-top": "120px !important",
        "max-width": this.$vuetify.display.mobile ? "95%" : "85%",
      };
    },
  },

  methods: {
    ...mapMutations(["setCustomerData", "setLoadingCustomerData"]),
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    "$auth.loading"(newLoadingAuthValue, oldLoadingAuthValue) {
      if (
        !newLoadingAuthValue &&
        this.$auth.authenticated &&
        !Object.keys(this.$store.state.customerData).length
      ) {
        this.setLoadingCustomerData({ loadingCustomerData: true });
        this.$auth.getTokenSilently().then((token) => {
          this.$apollo
            .query({
              client: "customersClient",
              query: this.getCustomerQuery,
              context: {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              },
            })
            .then((res) => {
              if (res.errors) {
                this.$auth.logout();
                window.location.href = "https://mi.dvacapital.com/signup/";
              } else {
                const customerData = {
                  id: res.data.profile.id,
                  identifier: res.data.profile.identifier,
                  name: res.data.profile.name,
                  lastName: res.data.profile.lastName,
                  email: res.data.profile.emails.find(
                    (email) => email.principal
                  ).email,
                };
                this.setCustomerData({ customerData });
                this.setLoadingCustomerData({ loadingCustomerData: false });
              }
            })
            .catch(() => {
              this.$auth.logout();
              window.location.href = "https://mi.dvacapital.com/signup/";
            });
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    customerData(newCustomerData, oldCustomerData) {
      window.Intercom("boot", {
        app_id: "f96n507j",
        user_id: newCustomerData.id || "",
        name: newCustomerData.name || "",
        email: newCustomerData.email || "",
      });
    },
  },

  mounted() {
    window.Intercom("boot", {
      app_id: "f96n507j",
      user_id: this.customerData.id || "",
      name: this.customerData.name || "",
      email: this.customerData.email || "",
    });
  },
};
</script>

<style lang="scss">
#main {
  background: #f2f2f2;
  font-family: "Work Sans", Helvetica, Arial, sans-serif;
}

.app-width {
  max-width: 2560px;
  margin: 0 auto;
}

// REUSABLE STYLES

.step-title-container {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  @media (max-width: 600px) {
    margin-bottom: 40px;
    > * {
      max-width: 240px;
    }
  }
}

h1 {
  font-weight: 600;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 40px;
  max-width: 420px;

  @media (max-width: 1400px) {
    font-size: 48px;
  }

  @media (max-width: 600px) {
    font-size: 30px;
    line-height: 140%;
  }
}

h2 {
  font-weight: 600;
  font-size: 45px;
  line-height: 120%;
  text-align: center;
  color: #000000;
  max-width: 500px;

  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 140%;
  }
}
h3 {
  font-weight: 600 !important;
  margin-bottom: 60px;
  font-size: 40px;
  line-height: 140%;

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 40px;
  }
}

h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 160%;
}
h5 {
  font-weight: 600 !important;
  font-size: 20px;
  line-height: 140%;
  @media (max-width: 600px) {
    font-size: 16px;
  }
}

h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
}

// FLEXBOX

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  flex-direction: column;
}
</style>
