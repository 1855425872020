<template>
  <v-container
    :style="$vuetify.display.mobile ? 'padding-top: 60px !important' : ''"
  >
    <v-row class="text-center" justify="center">
      <v-col cols="12">
        <GiftCardsIntro
          @gift="$router.push({ name: 'ChooseGiftCard' })"
          @redeem="$router.push('redeem')"
        ></GiftCardsIntro>
        <GiftInstructions :instructions="instructions" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import GiftInstructions from "../components/gift/GiftInstructions.vue";
import GiftCardsIntro from "../components/home/GiftCardsIntro.vue";
import { GIFT_INSTRUCTIONS } from "../values/giftcards-instructions.js";

export default {
  name: "Home",

  components: {
    GiftInstructions,
    GiftCardsIntro,
  },

  data: () => ({
    instructions: GIFT_INSTRUCTIONS,
  }),

  computed: {
    ...mapState(["giftCardCode"]),
  },

  mounted() {
    if (this.giftCardCode) {
      this.$router.push("customer-account");
    }
  },
};
</script>

<style scoped lang="scss"></style>
