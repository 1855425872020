import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import apolloProvider from "./gql";
import VueAuth0Plugin from "vue-auth0-plugin";
import { store } from "./store";
import { filters } from "./filters";
import { loadFonts } from "./plugins/webfontloader";

loadFonts();

const app = createApp(App);

app.config.globalProperties.$filters = filters;

// Emergency extra dependency, since it is not yet supported for Vue 3 in vuetify.
import VueSmoothScroll from "vue3-smooth-scroll";
app.use(VueSmoothScroll);

app.use(store);
app.use(router);
app.use(vuetify);

app.use(VueAuth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  redirect_uri: window.location.origin,
  cache_location: "localstorage",
  scope: "openid email",
});
app.use(apolloProvider);
app.mount("#app");
