<template>
  <v-app-bar id="header-container" flat height="80">
    <div class="header-logos" @click="logoClick()">
      <img :src="require('@/assets/header-logos.webp')" alt="dva-logo-header" />
    </div>
    <v-spacer></v-spacer>

    <div class="buttons-row">
      <span class="redeem" @click="$router.push('redeem')"> Canjear </span>
      <div class="gift-btn">
        <NewButton
          text="Regalar"
          @on-click="$router.push('choose-gift-card')"
        ></NewButton>
      </div>
    </div>

    <v-menu offset="20" rounded="lg" v-if="$auth.authenticated && customerData">
      <template v-slot:activator="{ props }">
        <div
          class="initials-circle outline-circle"
          v-bind="props"
          v-if="customerInitials"
        >
          <div class="initials-text">
            {{ customerInitials }}
          </div>
        </div>
      </template>
      <v-card
        class="session-menu"
        height="120px"
        width="200px"
        rouded="lg"
        elevation="0"
      >
        <v-list>
          <v-list-item>
            <a :href="`https://mi.dvacapital.com/`" target="_blank">
              <span> Ir a Mi DVA </span>
            </a>
          </v-list-item>
          <v-list-item>
            <span @click="$auth.logout()"> Cerrar sesión </span>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "Header",

  components: { NewButton },

  data: function () {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState(["customerData"]),
    customerInitials() {
      if (Object.keys(this.customerData).length === 0) return "";

      const fullName =
        this.customerData.name.split(" ")[0] + ` ${this.customerData.lastName}`;
      const words = fullName.split(" ");
      let initials = "";
      if (words[0]) {
        initials += words[0].toString().substring(0, 1);
      }
      if (words[1]) {
        initials += words[1].toString().substring(0, 1);
      }
      return initials;
    },
  },

  methods: {
    ...mapActions(["setGiftCardCode"]),
    logoClick: function () {
      this.setGiftCardCode(null);
      this.$router.push("/");
    },
    redeemClick: function () {
      this.$router.push("redeem");
    },
    giftClick: function () {
      this.$router.push("gift");
    },
  },
};
</script>

<style scoped lang="scss">
#header-container {
  width: 100% !important;
  background: #f2f2f2;
  padding: 0 10%;
  @media (max-width: 600px) {
    padding: 0 10px;
  }
}

#menu-option-separator {
  width: 100%;
  border: 1px solid #ffffff;
}

a {
  text-decoration: none;
}

.menu-options,
.session-menu {
  font-family: "Work Sans", Helvetica, Arial, sans-serif;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #333333;
    &:hover {
      cursor: pointer;
    }
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.menu-options > * {
  color: #fff;
  a {
    color: #fff;
  }
}

.session-menu > * {
  color: #000;
  a {
    color: #000;
  }
}

.header-logos {
  cursor: pointer;
  img {
    height: 40px;
    @media (max-width: 600px) {
      height: 30px;
    }
  }
}

.buttons-row {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 0 40px;
  @media (max-width: 600px) {
    gap: 0 20px;
  }
}

.redeem {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #000000;
  &:hover {
    cursor: pointer;
  }
}

.gift-btn :deep .reusable-btn {
  width: 160px !important;
  height: 50px !important;

  @media (max-width: 600px) {
    width: 92px !important;
    height: 40px !important;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
}

.user-menu {
  margin-left: 20px;
}

.initials-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: #000000;
}

.initials-circle {
  aspect-ratio: 1;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 600px) {
    height: 40px;
    margin-left: 20px;
  }
}
.outline-circle {
  background: #ffffff;
  border: 1px solid #333333;
}
</style>
