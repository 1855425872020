<template>
  <div class="footer-wrapper">
    <v-footer class="footer">
      <div>
        <div class="footer-content">
          <img
            :src="require('@/assets/dva-logo-white.svg')"
            alt="dva-logo-footer"
          />

          <div class="footer-grid-content">
            <div class="footer-section">
              <h4>DVA Capital</h4>
              <div
                v-for="route in dvaRoutes()"
                :key="route.value"
                class="footer-route"
              >
                <span @click="handleRoute(route)">
                  {{ route.title }}
                </span>
              </div>
            </div>

            <div class="footer-section">
              <h4>Mi DVA</h4>
              <div
                v-for="route in miDvaRoutes()"
                :key="route.value"
                class="footer-route"
              >
                <span @click="handleRoute(route)">
                  {{ route.title }}
                </span>
              </div>
            </div>

            <div class="footer-section">
              <h4>Legal</h4>
              <div
                v-for="route in legalRoutes()"
                :key="route.value"
                class="footer-route"
              >
                <span @click="handleRoute(route)">
                  {{ route.title }}
                </span>
              </div>
            </div>

            <div class="footer-section mi-dva-app">
              <h4>App Mi DVA</h4>

              <a
                href="https://apps.apple.com/cl/app/mi-dva/id1592185076"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  :src="require('@/assets/app-store-footer.png')"
                  alt="app-store-footer"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dvacapital.midva&hl=en_US&gl=GB"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  :src="require('@/assets/play-store-footer.png')"
                  alt="play-store-footer"
                />
              </a>
            </div>
          </div>
        </div>

        <div class="footer-disclaimer">
          La información y antecedentes que se muestran y entregan en
          www.dvacapital.com fueron obtenidos de fuentes públicas, por lo que
          DVA Capital ni sus directores, representantes o ejecutivos se hacen
          responsables de su contenido, veracidad o exactitud. DVA Capital es
          agente colocador de Compass Group Chile S.A. Administradora General de
          Fondos. DVA Capital mantiene un contrato con Compass Group Chile S.A.
          Administradora General de Fondos para administrar la cartera de
          algunos de los fondos que se distribuyen a través de DVA Capital. La
          remuneración por los servicios de administración de cartera es pagada
          por Compass Group Chile S.A. Administradora General de Fondos y no por
          los fondos. Los fondos mutuos y de inversión a los cuales Usted puede
          acceder por intermedio de DVA Capital están fiscalizados por la
          Comisión para el Mercado Financiero. Mayor información o antecedentes
          de dichos fondos los puede encontrar en https://cgcompass.com/ y en
          www.cmfchile.cl. Infórmese de las características esenciales de la
          inversión en estos fondos, las que se encuentran contenidas en su
          reglamento interno. La rentabilidad o ganancia obtenida en el pasado
          por este fondo, no garantiza que ella se repita en el futuro. Los
          valores de las cuotas de los fondos son variables. Para informarse de
          la situación financiera del fondo, revise sus estados financieros.
          Antes de invertir, le recomendamos informarse y pedir toda aquella
          información que Usted pueda necesitar para tomar una adecuada, y
          acorde su perfil, decisión de inversión. © 2024 DVA Capital
          desarrollado por DVA Tools SpA - Algunos derechos reservados

          <div class="copyright">
            © 2022 DVA Capital desarrollado por DVA Tools SpA - Algunos derechos
            reservados
          </div>
        </div>
      </div>
    </v-footer>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

const handleRoute = (route) => {
  if (route.value === "chat") {
    return window.Intercom("show");
  }
  if (route.internalRoute) {
    router.push({ name: route.value });
  } else {
    window.open(route.value, "_blank");
  }
};

const dvaRoutes = () => [
  {
    title: "Equipo",
    value: "https://www.dvacapital.com/nosotros",
    internalRoute: false,
  },
  {
    title: "Fondos",
    value: "https://www.dvacapital.com/fondos",
    internalRoute: false,
  },
  {
    title: "Blog",
    value: "https://blog.dvacapital.com/",
    internalRoute: false,
  },
  {
    title: "Centro de ayuda",
    value: "https://ayuda.dvacapital.com/es/",
    internalRoute: false,
  },
  {
    title: "Comparador de fondos",
    value: "https://fondos.dvatools.com/",
    internalRoute: false,
  },
];

const miDvaRoutes = () => [
  {
    title: "Entrar",
    value: "https://mi.dvacapital.com/login",
    internalRoute: false,
  },
  {
    title: "Crear una cuenta",
    value: "https://mi.dvacapital.com/signup",
    internalRoute: false,
  },
  {
    title: "Chat",
    value: "chat",
    internalRoute: false,
  },
];

const legalRoutes = () => [
  {
    title: "Código de conducta",
    value:
      "https://dvacapital-files.s3.amazonaws.com/C%C3%B3digo+de+Conducta+DVA+Capital.pdf",
    internalRoute: false,
  },
];
</script>

<style lang="scss" scoped>
.footer {
  background-color: #000;
  color: #fff;
}
.footer-wrapper {
  display: block;
}

.footer-content {
  padding: 8% 12%;

  @media (max-width: 960px) {
    padding: 60px 30px;
  }
}

.footer-grid-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 50px;
  gap: 60px 100px;

  @media (max-width: 1280px) {
    gap: 60px;
  }

  @media (max-width: 960px) {
    display: block;
    margin-top: 40px;
  }
}

.footer-section + .footer-section {
  @media (max-width: 960px) {
    margin-top: 40px;
  }
}

.footer-section span {
  max-width: 100px;

  @media (max-width: 960px) {
    max-width: 100%;
  }
}

.mi-dva-app {
  img {
    margin-top: 20px;
    display: block;
    max-width: 200px;
  }
}

.footer-route {
  span:hover {
    cursor: pointer;
  }

  margin-top: 20px;

  @media (max-width: 960px) {
    margin-top: 10px;
  }
}

.support-of {
  display: flex;
  align-items: center;
  gap: 0 100px;
  margin-top: 60px !important;

  @media (max-width: 960px) {
    display: block;
    margin-top: 80px !important;
  }
}

.supported-by {
  font-size: 14px;
  line-height: 160%;
  font-weight: 600;

  img {
    max-width: 200px;

    @media (max-width: 600px) {
      max-width: 150px;
    }
  }
}

.supported-by + .supported-by {
  @media (max-width: 960px) {
    margin-top: 40px;
  }
}

.supported-by div:first-child {
  margin-bottom: 20px;
}

.footer-disclaimer {
  font-size: 11px;
  line-height: 150%;
  text-align: justify;
  padding: 0px 40px 40px 40px;
  width: 100%;

  @media (max-width: 960px) {
    padding: 20px 10px 20px 10px;
  }
}

.copyright {
  margin-top: 20px;
}
</style>
