const filters = {
  toCurrency(value) {
    const formatter = new Intl.NumberFormat("de-DE", {
      style: "decimal",
      minimumFractionDigits: 0,
    });
    return `$${formatter.format(value)}`;
  },
  formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("es-ES", options);
  },
  color(name) {
    const colorsDictionary = {
      PURPLEDVA100: "#9C20E9",
      PURPLEDVA30: "#FAF4FF",
      BLACK: "#000000",
      GREY01: "#333333",
      GREY02: "#646363",
      GREY03: "#828282",
      GREY04: "#BDBDBD",
      GREY05: "#E2E0E0",
      GREYBACKGROUND: "#F2F2F2",
      WHITE: "#FFFFFF",
      RED: "#F20E45",
    };
    return colorsDictionary[name];
  },
};

export { filters };
