<template>
  <div class="gift-cards-intro-row-container">
    <div class="gift-card-intro-left-col">
      <div class="main-title-wrapper">
        <h1 class="main-hero">Regala para el Futuro, regala Inversiones</h1>
      </div>

      <div class="mobile-flex-reverse">
        <div class="buttons-wrapper">
          <div class="gift-btn">
            <NewButton
              text="Regalar Gift Card"
              @on-click="$emit('gift')"
            ></NewButton>
          </div>
          <div class="redeem-btn">
            <NewButton
              text="Canjear Gift Card"
              @on-click="$emit('redeem')"
            ></NewButton>
          </div>
        </div>
        <img
          :src="require('@/assets/sample-cards-mobile.webp')"
          class="sample-cards"
          v-if="$vuetify.display.mobile"
        />
      </div>
    </div>

    <img
      :src="require('@/assets/sample-cards.webp')"
      class="sample-cards"
      v-if="!$vuetify.display.mobile"
    />
  </div>
</template>

<script>
import NewButton from "../shared/NewButton.vue";

export default {
  name: "GiftCardsIntro",
  components: { NewButton },
};
</script>

<style lang="scss" scoped>
.gift-cards-intro-row-container {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0 175px;
  margin-bottom: 190px;

  @media (max-width: 1280px) {
    gap: 60px 0;
    flex-direction: column;
  }
  @media (max-width: 600px) {
    gap: 60px 0;
    margin-bottom: 80px;
  }
}

.sample-cards {
  width: 100%;
  max-width: 580px;

  @media (max-width: 1280px) {
    max-width: 420px;
  }
}

.main-title-wrapper {
  display: flex;
  justify-content: center;
}

.buttons-wrapper > div > * {
  max-width: 420px;
}

.buttons-wrapper > :first-child {
  margin-bottom: 20px;
}

.redeem-btn :deep .reusable-btn {
  background-color: #f2f2f2 !important;
  border: 1px solid #000000;
  span {
    color: #000000 !important;
  }
}

.main-hero {
  text-align: left;
  @media (max-width: 1280px) {
    text-align: center;
  }
}

.mobile-flex-reverse {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  gap: 40px 0;
}
</style>
