<template>
  <div>
    <h3>¿Cómo funciona?</h3>
    <div
      v-for="instructionsItem in instructions"
      :key="instructionsItem.title"
      class="instruction-item"
    >
      <h5 class="instruction-text-title">
        {{ instructionsItem.title }}
      </h5>

      <p class="instruction-text-body">
        {{ instructionsItem.body }}
      </p>
    </div>
    <Conditions />
  </div>
</template>

<script>
import Conditions from "../shared/Conditions.vue";

export default {
  name: "GiftInstructions",

  components: {
    Conditions,
  },
  props: {
    instructions: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.instruction-item {
  background: #ffffff;
  border-radius: 10px;
  padding: 40px;
}

.instruction-item + .instruction-item {
  margin-top: 20px;
}

.instruction-text-title {
  color: #000000;
  margin-bottom: 10px;
}

.instruction-text-body {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 160%;
  color: #333333;
  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.instruction-item {
  text-align: left !important;
}
</style>
