import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";

const cache = new InMemoryCache({
  addTypename: false,
});

const operationsClient = new ApolloClient({
  cache,
  uri: process.env.VUE_APP_OPERATOINS_GQL_API,
});

const customersClient = new ApolloClient({
  cache,
  uri: process.env.VUE_APP_CUSTOMERS_GQL_API,
});

const apolloProvider = createApolloProvider({
  clients: {
    operationsClient,
    customersClient,
  },
  defaultClient: operationsClient,
});

export default apolloProvider;
