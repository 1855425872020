<template>
  <v-btn
    class="new-btn"
    :loading="loading"
    :disabled="disabled"
    :class="`${buttonClass}-btn`"
    :type="type"
    :style="`background-color: ${overwrittingStyles.backgroundColor};`"
    :href="link"
    :target="link ? '_blank' : '_self'"
    @click="$emit('on-click')"
  >
    <span>
      {{ text }}
    </span>
    <template v-slot:loader>
      <div class="center-in-page loader">
        <div class="circle" v-for="n in 3" :key="n" />
      </div>
    </template>
  </v-btn>
</template>

<script>
export default {
  name: "NewButton",

  props: {
    text: String,
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    buttonClass: {
      type: String,
      default: "reusable",
    },
    link: String,
  },

  computed: {
    overwrittingStyles() {
      return {
        ...(this.disabled
          ? { backgroundColor: "#BDBDBD !important" }
          : { backgroundColor: "" }),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.new-btn {
  box-shadow: none;
  letter-spacing: normal;
  text-transform: none;
}
.loader {
  .circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    animation: grow 1s linear infinite;
    margin: 7px;
    animation-delay: 0.33s;
  }
  .circle:nth-child(2) {
    animation: grow 1s linear infinite;
    animation-delay: 0.66s;
  }
  .circle:nth-child(3) {
    animation: grow 1s linear infinite;
  }
  @keyframes grow {
    0% {
      transform: scale(1.5);
    }
    25% {
      transform: scale(0.75);
    }
    75% {
      transform: scale(0.75);
    }
    100% {
      transform: scale(1.5);
    }
  }
}

.reusable-btn {
  width: 100% !important;
  height: 60px !important;
  border-radius: 10px !important;
  background-color: #000000;

  span {
    color: #ffffff !important;
    font-size: 16px;
    line-height: 19px;
  }
}
</style>
