export const GIFT_INSTRUCTIONS = [
  {
    title: "¿Cuándo recibiré la Gift Card?",
    body: "Recibirás la Gift Card en tu email, dentro de las siguientes 24 horas hábiles del pago.",
  },
  {
    title: "¿Qué vigencia tiene una Gift Card?",
    body: "Las Gift Card tienen una vigencia de 1 año desde comprada. Si es que en ese plazo no es canjeada, el monto será devuelto a la cuenta de quien la compró.",
  },
  {
    title: "¿Cómo recibiré la Gift Card?",
    body: "Las Gift Card son 100% digitales. Una vez comprada, te la enviaremos a tu email para que la reenvíes al destinatario o la imprimas y se la regales en persona.",
  },
  {
    title: "¿Qué montos puedo regalar?",
    body: "El monto que regalas en una Gift Card la defines tú. La única restricción es que cada Gift Card debe ser al menos de $5.000 y máximo de $1.000.000.",
  },
  {
    title: "¿Puedo comprar una Gift Card sin tener cuenta en DVA?",
    body: "Sí, cualquiera puede comprar una Gift Card.",
  },
  {
    title: "¿Puedo comprar una Gift Card para un menor de edad?",
    body: "No. Al momento de canjear la Gift Card, el destinatario deberá ser mayor de edad.",
  },
  {
    title: "¿Puedo regalar una Gift Card a alguien que no tiene cuenta en DVA?",
    body: "Sí, pero para canjear el destinatario deberá crear una cuenta.",
  },
];

export const REDEEM_INSTRUCTIONS = [
  {
    title: "100% digital y con código",
    body: "La Gift Card funciona de forma 100% digital y cuenta con un código de canje que es único e intransferible. Con ese código, puedes hacer el canje en simples pasos.",
  },
  {
    title: "Intransferible",
    body: "La Gift Card tiene un monto que, una vez canjeada, será depositado en el fondo de inversión correspondiente, a nombre de quien la recibió. La Gift Card no puede ser canjeada por otra persona que no sea el destinatario original.",
  },
  {
    title: "1 año de vigencia",
    body: "Las Gift Card tienen una vigencia de 1 año desde comprada. Si es que en ese plazo no es canjeada, el monto será devuelto a la cuenta de quien la compró.",
  },
  {
    title: "Requisitos del destinatario",
    body: "Al momento de canjear la Gift Card, el destinatario debe ser mayor de edad y tener una cuenta bancaria. De todas formas, la Gift Card tiene 1 año de vigencia, y puede ser canjeada en cualquier momento de ese plazo.",
  },
  {
    title: "Tiempos de canje",
    body: "Una vez hecho el canje, en 24 horas hábiles procesaremos y haremos efectiva la inversión. Por mientras, el canje puede verse reflejado en los movimientos en proceso en Mi DVA.",
  },
  {
    title: "Destino del dinero",
    body: "En el caso que el destinatario quiera que la Gift Card no sea canjeada como inversión, puede pedir que el monto sea depositado en su cuenta bancaria escribiéndonos a clientes@dvacapital.com",
  },
];
