import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const persistantDataStore = createPersistedState({
  paths: ["giftCardCode"],
  storage: window.sessionStorage,
});
const store = createStore({
  state() {
    return {
      loadingCustomerData: false,
      giftCardData: {},
      customerData: {},
      giftCardCode: null,
    };
  },
  mutations: {
    setLoadingCustomerData(state, payload) {
      state.loadingCustomerData = payload.loadingCustomerData;
    },
    setCustomerData(state, payload) {
      state.customerData = payload.customerData;
    },
    setGiftCardData(state, payload) {
      state.giftCardData = payload;
    },
    setGiftCardCode(state, payload) {
      state.giftCardCode = payload;
    },
  },
  actions: {
    setGiftCardData({ commit }, payload) {
      commit("setGiftCardData", payload);
    },
    setGiftCardCode({ commit }, payload) {
      commit("setGiftCardCode", payload);
    },
  },
  plugins: [persistantDataStore],
});

export { store };
