import gql from "graphql-tag";

const getCustomerQuery = gql`
  query profile {
    profile {
      id
      name
      lastName
      identifier
      gender
      emails {
        principal
        email
      }
    }
  }
`;

export { getCustomerQuery };
